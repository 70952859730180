.footer-container {
  width: 100%;
  height: auto;
  background: #814c53;
  padding: 20px 5vw 30px 5vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    @media only screen and (max-width: 600px) {
      &:first-child {
        margin-bottom: 5px;
      }
    }
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
