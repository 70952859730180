.list {
  width: 100%;
  height: 100%;
  background: rgba(218, 193, 133, 0.1);
  .container {
    width: 100%;
    max-width: 1240px;
    height: 100%;
    padding: 5vh 5vw;
    box-sizing: border-box;
    margin: 0 auto;
    text-align: left;
    border-bottom: 2px solid #eee;
    @media only screen and (max-width: 900px) {
      text-align: center;
    }
    h2 {
      font-weight: 500;
      color: #225180;
      text-transform: uppercase;
      margin-top: 0px;
      margin-bottom: 5px;
    }
    &:first-child {
      h2 {
        margin-top: 5vh;
      }
    }
    p {
      color: rgb(114, 114, 114);
    }
    .list-box-container {
      display: flex;
      padding: 3vh 0;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: 900px) {
        flex-direction: column;
        border-bottom: 1px solid #e2e1e1;
        &:last-child {
          border-bottom: 0;
        }
      }
      .list-box {
        text-align: left;
        &:first-child {
          width: 70%;
          border-right: 2px solid #eee;
          padding-right: 5%;
          @media only screen and (max-width: 900px) {
            width: 100%;
            text-align: center;
            padding-right: 0;
            border-right: 0;
          }
        }
        &:last-child {
          width: 20%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @media only screen and (max-width: 900px) {
            width: 100%;
            text-align: center;
          }
        }
        h3 {
          color: rgb(153, 51, 76);
          font-weight: 500;
          margin-bottom: 10px;
        }
        a {
          color: #225180;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 15px;
          border-radius: 25px;
          font-weight: 500;
          &:first-child {
            margin-right: 20px;
          }
        }
        span {
          margin-bottom: 14px;
          font-weight: 400;
          color: #666;
        }
      }
    }
  }
}
