.link {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 35px;
  transition: 300ms;
  cursor: pointer;
}

.link:hover {
  color: rgba(255, 196, 106, 1);
}

.link:last-child {
  margin-right: 0px;
}

.logoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.box-link {
  text-decoration: none;
}

@media only screen and (max-width: 900px) {
  .link {
    margin: 20px 0px 10px 0px;
  }
}
