.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .gallery {
    width: 100%;
    height: 100%;
    background: rgba(218, 193, 133, 0.1);
    .container {
      width: 100%;
      height: 100%;
      padding: 5vh 5vw 7vh 5vw;
      box-sizing: border-box;
      margin: 0 auto;
      text-align: center;
      h2 {
        font-weight: 500;
        color: #225180;
        text-transform: uppercase;
      }
      .gallery-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .image-gallery-container {
          width: 24%;
          max-height: 40vh;
          overflow: hidden;
          margin-bottom: 2.5vh;
          @media only screen and (max-width: 1300px) {
            width: 32%;
          }
          @media only screen and (max-width: 1065px) {
            width: 49%;
            max-height: 55vh;
          }
          @media only screen and (max-width: 600px) {
            width: 100%;
            max-height: auto;
            height: auto;
          }
          img {
            width: 100%;
            @media only screen and (max-width: 600px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
