.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .topbar-content {
    height: 100vh;
    width: 100%;
    background: rgba(21, 27, 53, 1);
    display: flex;
    box-sizing: border-box;
    align-items: flex-end;
    justify-content: space-around;
    .topbar-content-container {
      height: 100vh;
      width: 100%;
      max-width: 1240px;
      padding: 0vh 5vw;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      .container-content-box {
        height: 90vh;
        display: flex;
        &:first-child {
          align-items: flex-end;
          img {
            height: 95%;
          }
        }
        &:last-child {
          align-items: center;
          transform: translateX(-3vw);
          img {
            padding-top: 10vh;
          }
        }
      }
    }
  }
  .about-home-content {
    width: 100%;
    height: 100%;
    background: rgba(218, 193, 133, 0.1);
    .container {
      width: 100%;
      max-width: 1240px;
      height: 100%;
      padding: 5vh 5vw;
      box-sizing: border-box;
      margin: 0 auto;
      text-align: center;
      h2 {
        font-weight: 500;
        color: #225180;
        text-transform: uppercase;
      }
      p {
        color: rgb(114, 114, 114);
      }
    }
  }
  .gallery {
    width: 100%;
    height: 100%;
    background: rgba(218, 193, 133, 0.1);
    .container {
      width: 100%;
      height: 100%;
      padding: 5vh 5vw 7vh 5vw;
      box-sizing: border-box;
      margin: 0 auto;
      text-align: center;
      h2 {
        font-weight: 500;
        color: #225180;
        text-transform: uppercase;
      }
      .gallery-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .image-gallery-container {
          width: 24%;
          height: 45vh;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
