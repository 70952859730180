.list {
  width: 100%;
  height: 100%;
  background: #fff;
  .service-container {
    width: 100%;
    max-width: 1240px;
    height: 100%;
    padding: 10vh 5vw 6vh 5vw;
    box-sizing: border-box;
    margin: 0 auto;
    border-bottom: 2px solid #eee;
    @media only screen and (max-width: 900px) {
      text-align: center;
    }
    h2 {
      font-weight: 500;
      color: #225180;
      text-transform: uppercase;
      margin-top: 0px;
      margin-bottom: 5px;
    }
    .serviceName {
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
    }
    .serviceLink {
      width: 100%;
      text-align: center;
      margin: 5vh 0;
      a {
        padding: 15px 30px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        background: #225180;
        color: #fff;
        border: 2px solid #225180;
        box-sizing: border-box;
        transition: 300ms;
        &:hover {
          background: none;
          color: #225180;
        }
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
    }
    p {
      color: rgb(114, 114, 114);
      text-align: center;
    }
    .list-box-container-single {
      display: flex;
      padding: 3vh 0;
      flex-direction: column;
      @media only screen and (max-width: 900px) {
        flex-direction: column;
        border-bottom: 1px solid #e2e1e1;
        &:last-child {
          border-bottom: 0;
        }
      }
      .list-box {
        text-align: center;
        width: 100%;
        h3 {
          color: rgb(153, 51, 76);
          font-weight: 500;
          margin-bottom: 10px;
        }
        a {
          color: #225180;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 15px;
          border-radius: 25px;
          font-weight: 500;
          &:first-child {
            margin-right: 20px;
          }
        }
        span {
          margin-bottom: 14px;
          font-weight: 400;
          color: #666;
        }
      }
    }
  }
}
