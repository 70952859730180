.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .privacy {
    width: 100%;
    height: 100%;
    background: rgba(218, 193, 133, 0.1);
    .container {
      width: 100%;
      height: 100%;
      padding: 5vh 5vw 7vh 5vw;
      box-sizing: border-box;
      margin: 0 auto;
      text-align: left;
      h2 {
        font-weight: 500;
        color: #225180;
        text-transform: uppercase;
        text-align: center;
      }
      a {
        color: #000;
      }
    }
  }
}
