.awards-container {
  width: 100%;
  height: 100%;
  padding: 5vh 5vw;
  box-sizing: border-box;
  text-align: center;
  h2 {
    font-weight: 500;
    color: #225180;
    text-transform: uppercase;
  }
  .award-box {
    padding: 4vh 0px;
    text-align: center;
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: 0px;
    }
    h3 {
      font-weight: 500;
      margin: 0px;
      &:first-child {
        color: rgb(203, 167, 97);
      }
      &:nth-child(2) {
        color: #225180;
        font-size: 13px;
      }
      &:last-child {
        margin-top: 10px;
        font-size: 15px;
        color: #666666;
        font-weight: 400;
      }
    }
  }
}
