h4 {
  text-align: center;
  font-size: 15px;
  margin-top: 0px;
  color: #666;
  font-weight: 600;
}

form {
  margin: 0 auto;
  max-width: 700px;
  text-align: center;
  label {
    text-align: left;
    font-weight: bold;
    color: rgb(153, 51, 76);
    margin-bottom: 10px;
  }
  input,
  select {
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-left: 3px solid rgb(153, 51, 76);
    padding: 12px 15px;
    font-size: 15px;
    margin-bottom: 16px;
    &:focus {
      outline: none;
    }
  }
  .checkbox-container {
    text-align: justify;
    label {
      font-weight: 500;
      color: #666;
      margin-left: 6px;
    }
    input[type="checkbox"] {
      margin-bottom: 0px;
    }
  }
  input[type="submit"] {
    margin-top: 5vh;
    padding: 15px 30px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    background: #225180;
    color: #fff;
    border: 2px solid #225180;
    box-sizing: border-box;
    transition: 300ms;
    cursor: pointer;
    &:hover {
      background: none;
      color: #225180;
    }
  }
}
