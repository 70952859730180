.contact-container {
  width: 100%;
  background: #9a656c;
  position: relative;
  padding: 10vh 5vw;
  box-sizing: border-box;
  display: flex;
  @media only screen and (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10vh 0;
  }
}
