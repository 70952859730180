.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .contact-content {
    width: 100%;
    height: 100%;
    background: rgba(218, 193, 133, 0.1);
    .container {
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding: 5vh 5vw;
      box-sizing: border-box;
      margin: 0 auto;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      @media only screen and (max-width: 1100px) {
        flex-direction: column;
      }
      .image-container {
        width: 40%;
        height: 75vh;
        overflow: hidden;
        @media only screen and (max-width: 1100px) {
          width: 100%;
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 900px) {
          height: auto;
        }
        img {
          transform: translateY(-20%);
          width: 100%;
          @media only screen and (max-width: 1100px) {
            transform: translateY(-30%);
          }
          @media only screen and (max-width: 900px) {
            transform: translateY(0%);
          }
        }
      }
      .contact {
        text-align: justify;
        width: 50%;
        @media only screen and (max-width: 1100px) {
          width: 100%;
        }
        h2 {
          font-weight: 500;
          color: #225180;
          text-transform: uppercase;
          @media only screen and (max-width: 1100px) {
            width: 100%;
            text-align: center;
          }
        }
        p {
          color: rgb(114, 114, 114);
        }
      }
    }
  }
}
